<template>
  <div class="language has-border-left d-flex">
      <VDropdown placement="bottom" ref="languagePopover" :distance="13">
        <div class="language-popover-toggle d-flex">
          <span class="language-flag" v-text="currentLanguage" />
        </div>

        <template #popper>
          <ul class="popover-linklist">
            <li
              v-for="lang in languages"
              :key="lang.code"
              class="d-block align-center"
              v-close-popover
            >
              <button
                class="d-block"
                type="button"
                v-text="lang.label"
                @click="changeLanguage(lang.code)"
              />
            </li>
          </ul>
        </template>
      </VDropdown>
  </div>
</template>
<script>
import { appSettings } from '@/settings'
import * as utils from '../utils.js'

export default {
  name: 'LanguageSelector',

  components: {
  },

  computed: {
    currentLanguage () {
      const entries = appSettings.languages

      return Object.keys(entries).find(item => {
        return item === this.$i18n.locale
      }) || this.$i18n.locale
    },

    languages () {
      const entries = appSettings.languages

      return utils.sortListByLabel(Object.keys(entries)
        .filter(item => item !== this.$i18n.locale)
        .map(key => ({
          code: key,
          label: entries[key]
        })))
    }
  },

  methods: {
    changeLanguage (code) {
      this.$refs.languagePopover.hide()
      this.$router.push({ name: this.$route.name, params: { lang: code }, query: this.$route.query })
      this.$i18n.locale = code
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports';

 .popover-linklist {
   /* use as much space as possible, and remove some pixels because of positioning (so that hte whole list is visible) */
   /* also leave enough space for the header, so popper does not reposition the popover element automatically */
  max-height: calc(50vh);
  overflow-y: auto;
}

.language {
    font-size: 16px;
    line-height: 1;
    align-items: center;

    &-name {
      padding-left: 1rem;
    }

    &-flag {
      text-transform: uppercase;
    }

    &-popover-toggle {
      position: relative;
      align-items: center;
      cursor: pointer;
      padding-right: 20px;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate3d(0, -50%, 0);
        border-top: 4px solid $color-grey-600;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }
    };
}
</style>
