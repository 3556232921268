<template>
  <button
    @click="exit"
    v-text="$t('form.logout')"
    :class="['Button', customCss]"
  />
</template>

<script>
import { logout } from '../../auth/auth0'
export default {
  name: 'LogoutButton',
  props: {
    customCss: {
      type: String,
      default: ''
    }
  },
  methods: {
    exit (e) {
      e.preventDefault()
      logout()
    }
  }
}
</script>
<style lang="scss" scoped>
.Button {
  padding: .5em;
  font-size: .8em;
}
</style>
