<template>
  <div>
    <article class="ImageContent">
      <section class="left-container">
        <div class="left-container-inner">

          <slot name="image-headline" />
          <slot name="image" />
          <slot name="image-below" />

        </div>
      </section>

      <section
        class="right-container"
        :class="{'has-grey-bg': hasGreyBg}"
      >

        <link-router
          v-if="showNavigation"
          class-names="Logo d-block"
          path="home"
        >
          <img alt="myMEDel" src="@/assets/images/medel-logo.svg">
        </link-router>
        <a v-if="showLogo" class="Logo d-block">
          <img alt="myMEDel" src="@/assets/images/medel-logo.svg">
        </a>

        <div class="sub-menu">
          <link-router
            v-if="showNavigation"
            class-names="close-button d-block"
            path="home"
          >
            {{ $t('links.back') }}
          </link-router>
          <div class="content-logout-btn"><logout-button v-if="showLogout" /></div>
          <language-selector></language-selector>
        </div>

        <slot name="content"/>

      </section>
    </article>
    <the-portal-footer v-if="showFooter"/>
  </div>
</template>

<script>
import LanguageSelector from '@/components/LanguageSelector.vue'
import ThePortalFooter from '@/components/ThePortalFooter.vue'
import LogoutButton from '@/components/partials/LogoutButton.vue'

export default {
  name: 'ImageContent',

  components: {
    LanguageSelector,
    ThePortalFooter,
    LogoutButton
  },

  props: {
    showNavigation: {
      type: Boolean,
      default: false
    },
    showLogo: {
      type: Boolean,
      default: false
    },
    showLogout: {
      type: Boolean,
      default: false
    },
    hasGreyBg: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    routeToHome () {
      const locale = this.$i18n.locale

      this.$router.push({ name: 'home', params: { lang: locale }, query: this.$route.query })
    }
  }
}
</script>

<style lang="scss">
@import '../styles/_component-imports.scss';
@import 'vue-select/src/scss/vue-select.scss';
@import '../styles/_steps.scss';

.ImageContent {
  display: flex;
  flex-direction: column;
  background-color: $white;

  @include media('>=sm') {
    flex-direction: row;
  }

  .left-container {
    position: relative;
    background-image: url('../assets/images/background.jpg');
    background-size: cover;
    background-position: right center;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    min-height: 100vh;
    /* do not show the left-container with image on mobile */
    display: none;
    justify-content:space-around;

    @include media('>=sm') {
      position: fixed;
      width: 40%;
      /* show the left-container with image on non mobile  */
      display: flex;
    }
  }

  .left-container-inner {
    padding: 50px 20%;
    width: 100%;
    box-sizing: border-box;
    text-align: center;

    .Headline {
      margin-bottom: 10px;
    }

    figure {
      position: relative;
      max-width: 300px;
      margin: 0;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 5px;
        width: 100%;
        padding-bottom: 100%;
        border-radius: 50%;
        box-shadow: 0 28px 28px rgba(0, 0, 0, 0.2);
      }

      @include media('>=sm') {
        max-width: initial;
      }
    }

    img {
      width: 100%;
    }

    p {
      text-align: left;
      color: $color-grey-700;
      margin: 2em -2em 0;
    }

    ul {
      list-style: none;
      margin: 1em 0 0;
      padding: 0;
    }

    li {
      position: relative;
      padding-left: 1em;
      line-height: 1.4;
      margin-bottom: 0.5em;

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: .7em;
        left: 0;
        width: 3px;
        height: 3px;
        background-color: $color-grey-700;
        border-radius: 3px;
        transform: translate3d(0, -50%, 0);
      }
    }

    strong {
      font-weight: bold;
    }

    @include media('>=sm') {
      padding: 0 25%;
    }
  }

  .right-container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    width: 100%;
    /* leave room for the footer */
    min-height: calc(100vh - 61px);
    justify-content:center;

    @media (min-width:1200px) {
      justify-content:space-around;
    }

    &.has-grey-bg {
      background-color: $color-grey-100;
    }

    @include media('>=sm') {
      margin-left: 40%;
      width: 60%;
    }
  }

  .sub-menu {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
  }

  /* for all children in the submenu */
  .sub-menu > * {
    margin-right: 20px;
  }

  .close-button {
    @include animation-in(color);

    line-height: 1;
    color: $color-grey-500;

    &:hover {
      color: $black;
    }

    &::before {
      content: '';
      position: relative;
      display: inline-block;
      top: 3px;
      width: 10px;
      height: 10px;
      margin-right: 6px;
      border-left: 2px solid $black;
      border-bottom: 2px solid $black;
      transform: rotate(45deg) translate(0, -50%);
    }
  }

  .form-button {
    width: 100%;
    margin-top: 1em;
    cursor: pointer;

    &.is-grey {
      background-color: $color-grey-300;
    }

    &.is-link {
      position: relative;
      background: none;
      color: $color-grey-500;
      padding: 10px 0;
      margin-top: 5px;

      &:hover {
        color: $black;

        &::before {
          border-color: $black;
        }
      }

      &::before {
        content: '';
        position: relative;
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
        border-left: 2px solid $color-grey-500;
        border-bottom: 2px solid $color-grey-500;
        transform: rotate(45deg) translate(0, -50%);
      }
    }
  }

  .Step {
    position: relative;
  }

  .form-step-left-enter-active,
  .form-step-right-leave-active {
    transition: all 0.2s $transition-curve-deceleration;
  }

  .form-step-left-leave-active,
  .form-step-right-enter-active {
    transition: all 0.2s $transition-curve-acceleration;
  }

  .form-step-left-enter,
  .form-step-right-leave-to {
    transform: translate(15px, 0);
    opacity: 0;
  }

  .form-step-left-enter-to,
  .form-step-left-leave-active,
  .form-step-right-enter-to,
  .form-step-right-leave {
    transform: translate(0, 0);
    opacity: 1;
  }

  .form-step-left-leave-to,
  .form-step-right-enter {
    transform: translate(-15px, 0);
    opacity: 0;
  }
}

.Content {
  margin: 100px auto 50px;
  width: 100%;

  @include media('>=sm') {
    width: 420px;
  }

  @include media('>=md') {
    margin-bottom: 20px;
  }
}

.Logo {
  @media (min-width: 1200px) {
    position: absolute;
    top: 100px;
    left: 30px;
  }

  img {
    height: 40px;
  }

  @include media('>=sm') {
    left: initial;
    right: 100px;
  }
}

.RegFeedback {
  line-height: 1.5 !important;

  strong {
    font-weight: bold;
    display: block;
  }
}

.content-logout-btn {
  margin-right: 3em !important;
}

</style>
